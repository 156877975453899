// Azure
export const GET_TENANT_ID = "/tenant";
export const GET_CURRENT_USER = "/user";
export const GET_CURRENT_ROLE = "/user/role";
export const GET_ORG_USERS = "/users";
export const POST_ORG_USERS = "/users";

// AlienVault
export const GET_ALARMS = "/alarms";
export const GET_EVENTS = "/events";
export const GET_INVESTIGATIONS = "/investigations";
export const GET_DICTIONARIES = "/dictionaries";
export const GET_ALL_DWM = "/darkWebEvents";
export const GET_VULNERABILITIES = "/vulnerabilities";
export const GET_PACKAGES = "/packages";

// Lookout
export const GET_THREATS = "/mobile/threats";

// LMS
export const GET_TRAINING_LIST = "/users/training";
export const GET_COURSE_STATISTICS = "/courses/statistics";
export const GET_ASSIGNED_COURSES = "/requiredCourses";
export const GET_ALL_ASSIGNMENTS_COURSES = "/allCourseAssignments";
export const GET_COURSE_URL = "/course";


//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CALENDER
export const GET_CAL_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

